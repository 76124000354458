import { BLOG, STORIES } from "../routes";
import Page from "../components/Layout/Page";
import GatsbyImage from "gatsby-image";
import React from "react";
import { graphql } from "gatsby";
import "./MarkdownFile.css";

const Frontmatter = ({ file }) => (
    <header>
        <h1 className="my-5 text-3xl lg:text-6xl font-sans font-hairline text-center uppercase">
            {file.frontmatter.title}
        </h1>
        <blockquote
            className={
                "m-0 bg-gray-200 dark:bg-gray-800 dark:text-white text-gray-600 p-5 font-sans text-right"
            }
        >
            Published <strong>{file.frontmatter.date}</strong>
            <br />
            <strong>{file.timeToRead}mn</strong> to read
        </blockquote>
        {file.frontmatter.cover && (
            <figure className={"m-0 p-0 bg-white"}>
                <GatsbyImage
                    fluid={file.frontmatter.cover.childImageSharp.fluid}
                />
            </figure>
        )}
    </header>
);

const TableOfContents = ({ file }) => (
    <div
        className={
            "TableOfContents font-sans text-base md:text-lg bg-gray-200 dark:bg-gray-800 dark:text-white my-10 p-5"
        }
    >
        <div
            dangerouslySetInnerHTML={{
                __html: file.tableOfContents,
            }}
        />
    </div>
);

const MarkdownFile = ({
    data: { markdownRemark: file, location, ...props },
}) => {
    const isBlogpost = file.fields.type === "blogposts";
    const backLink = isBlogpost ? BLOG : STORIES;

    return (
        <Page
            to={backLink}
            title={file.frontmatter.title}
            layoutClassName={"bg-white dark:text-white dark:bg-gray-900"}
            className="p-5 lg:p-32 m-0 text-xl"
        >
            <Frontmatter file={file} />
            {isBlogpost && <TableOfContents file={file} />}
            <div
                className={`markdown leading-normal text-justify text-base md:text-xl font-sans ${
                    file.fields.type
                } p-3 md:p-10 xl:${
                    file.fields.type === "blogposts" ? "p-40" : "p-56"
                }`}
                dangerouslySetInnerHTML={{ __html: file.html }}
            />
        </Page>
    );
};

export const query = graphql`
    query FileQuery($fileId: String!) {
        markdownRemark(id: { eq: $fileId }) {
            html
            timeToRead
            tableOfContents(pathToSlugField: "fields.slug")
            fields {
                type
            }
            frontmatter {
                title
                date(fromNow: true)
                cover {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 2000) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default MarkdownFile;
